<template>
  <div class="BrowseView">
    <div class="body">
      <component
        :is="config.BrowseItem"
        v-for="itemId of itemIds"
        :key="itemId"
        class="Item"
        :item-id="itemId" />
    </div>
    <div class="header">
      <div
        class="exitButton --global-clickable"
        @click="$router.push(range ? '../browse' : 'home')">
        Exit
      </div>
    </div>
  </div>
</template>

<script>
export default {
    inject: ['config'],
    props: {
        range: {
            type: String
        }
    },
    computed: {
        itemIds() {
            if (this.range) {
                const [startId, endId] = this.range.split('-');
                const startIndex = parseInt(startId, 10);
                const endIndex = parseInt(endId, 10);
                return this.config.itemIds.slice(startIndex, endIndex + 1);
            } else {
                return this.config.itemIds;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.BrowseView {
    position: relative;
    // overflow-y: auto;
    // padding: 2em;
    // display: flex;
    // flex-direction: column;
}
.header {
    // border: 1px solid red;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.body {
    overflow-y: auto;
    padding: 2em;
    // flex: 1;
    height: 100%;
}
.Item {
    &:not(:first-child) {
        margin-top: 1em;
    }
}

.exitButton {
    padding: 1em;
    // color: rgba(white, 0.5);
}
</style>
